import './App.css';

import React, { useState } from 'react';
import logo from './assets/logo-pax-nippon.png';

function App() {

  const [count, setCount] = useState(0);
  const [fezSorteio, setFezSorteio] = useState('');
  const [fazendoSorteio, setFazendoSorteio] = useState(false)
  const [esperandoSorteio, setEsperandoSorteio] = useState(false);
  const [arrayNippon, setArrayNippon] = useState([]);
  const separarItems = (arrayGeral) => {
    setCount(arrayGeral.length);
    setArrayNippon(arrayGeral);
    console.log(arrayGeral)
  }

  const onFileChange = (e) => {
    if (!e) {
      document.getElementById('campo_input').value = '';
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => separarItems(e.target.result.split(/\r?\n|\r|\n/g));
  }

  const sortear = () => {
    let result = Math.round(Math.random() * (count - 1));
    let clienteSorteado = arrayNippon[result].split(';');
    setEsperandoSorteio(true)
    setTimeout(()=>{
      setFezSorteio(clienteSorteado[0]);
      setEsperandoSorteio(false)
    },5000)
  }

  const clear = () => {
    setFezSorteio('');
  };
  return (
    <div className="App">
      <header className="App-header">
        {
          arrayNippon.length == 0 &&
          <input type="file" onChange={onFileChange} Onaccept=".csv" id='campo_input' />
        }

      </header>
      <body>
        <div className='body'>
          <div style={{display:'flex', justifyContent:'end'}}>
          <div className='reload' style={{'cursor':'pointer', fontSize:'22px'}} onClick={clear}>⟳</div>

          </div>
          <div className='body-content'>
            <div className='body-content-title'>
              <img src={logo} className='logo-nippon' />
            </div>
            <div className='body-content-text'>
              {!fezSorteio && 
                <h2>
                  {/* Existem {count} participantes no sorteio atual. */}
                  LOGO LOGO CONHECEREMOS O(A) NOSSO GANHADOR(A)
                </h2>
              }
                 {esperandoSorteio &&
                 <h1 className='result'>Sorteando...</h1>
              }
              {fezSorteio.length > 0 &&
                <div className='result'>
                  <h2>
                    O(A) vencedor(a) do nosso sorteio foi....
                  </h2>
                  <h1> {fezSorteio}</h1>
                  <h1> PARABÉNS A(O) GANHADOR(A)!!!</h1>
                </div>

              }


            </div>
            {(!fezSorteio&&!esperandoSorteio) && 
                 <div className='button-content' onClick={sortear}>Sortear Cliente</div>
              }
           
            {/* <button onClick={clear} >Limpar</button> */}
          </div>
        </div>
      </body>
    </div>

  );

}

export default App;
